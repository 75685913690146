import React from "react";
import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { SvgIcon } from "../../common/SvgIcon";

import Container from "../../common/Container";
import i18n from "i18next";

import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  Empty,
  FooterContainer,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const footerStyles = `
  /* Custom CSS styles for the email link */
  .email-link {
    text-decoration: none; /* Remove underlines */
    font-size: 16px; /* Default font size */
  }

  /* Responsive font size adjustments */
  @media (max-width: 767px) {
    .email-link {
      font-size: 14px; /* Adjust font size for smaller screens */
    }
  }
`;

const Footer = ({ t }: any) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <img
          src={src}
          alt="Social Icon"
          width="25px"
          height="25px"
        />
      </a>
    );
  };

  return (
    <>
      {/* Inject the CSS styles as a styled component */}
      <style>{footerStyles}</style>

      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Language>{t("Contact")}</Language>
              <Para>
                {t(`Do you have any questions? Feel free to reach out.`)}
              </Para>
            </Col>
            <Col lg={10} md={10} sm={12} xs={12}>
              <Empty />
              <Language>{t("Address")}</Language>
              <Para>Fountain Valley</Para>
              <Para>California</Para>
              <Para>United States of America</Para>
            </Col>
          </Row>
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
            <NavLink to="/">
              <LogoContainer>
                <SvgIcon
                  src="logo.svg"
                  aria-label="homepage"
                  width="101px"
                  height="64px"
                />
              </LogoContainer>
            </NavLink>
            <a
              href="https://www.instagram.com/studentsuccessclub_/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Instagram"
            >
              <FontAwesomeIcon icon={faInstagram} size="2x" />
            </a>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
